import WhyChooseItem from './WhyChooseItem';

export default function WhyChoose({ chooseDatas, width }) {
   
    
    return (
        <section className="section section-no-border bg-background-home section-angled 
        py-1 m-0" >
            <div className="container pt-1">
                <div className="row align-items-center pt-md-3 mt-1">
                    <div className="col">
                        <h3 className="text-center text-11 line-height-3 
                            font-weight-bold text-color-primary mb-lg-3 mb-2">
                            {chooseDatas.secondTitle}
                        </h3>
                    </div>
                </div>
                <div className="row justify-content-center mt-md-3 pt-lg-2">
                    <div className="col">
                        <div className="row justify-content-center ">
                            {chooseDatas.items.map((item, i) =>
                                <WhyChooseItem
                                    key={Math.random().toString()}
                                    src={item.src}
                                    alt={item.alt}
                                    index={i + 1}
                                    upperTitle={item.upperTitle}
                                    lowerTitle={item.lowerTitle}
                                    definition={item.definition}
                                    delay={item.delay}
                                    width={width}
                                />)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
