import Image from 'next/image';
import HizmetlerTitle from './HizmetlerTitle';
import ButtonLink from '../../common/button/ButtonLink';

const PentestHome = ({dataPentest}) => {


    return (
        <section className="section-no-border mt-xl-0 p-relative z-index-1" 
        id="intro" style={{marginBottom:"8em"}}>
            <HizmetlerTitle title={dataPentest.title}/>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6 col-xl-6 col-md-6 d-flex flex-column justify-content-center 
                    align-items-start align_items_center">
                        <h3 className="pt-lg-5 py-2 mb-0 text-8 font-weight-bolder text-primary 
                        text_align_center line-height-3">
                             {dataPentest.subTitle} </h3>
                        <p className="text-5 line-height-3 text_align_center">
                            {dataPentest.definitionOne} 
                        </p>
                        <p className="text-5 line-height-3 text_align_center">
                            {dataPentest.definitionTwo} 
                        </p>
                        <div className="text_align_center"> 
                        <ButtonLink 
                            title={dataPentest.buttonName} 
                            page={dataPentest.link} />
                        </div>
                         
                    </div>
                    <div className="col-lg-4 col-md-6 py-4 py-lg-2 py-xl-4 px-md-3 d-flex" 
                        style={{width:"16em", height:"16em"}} >
                            <Image 
                                src="/images/pentest/pentest.png" 
                                alt="pentest" 
                                layout="fill" 
                                loading="lazy"
                                objectFit="contain"
                                objectPosition="center"
                                
                            />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PentestHome;