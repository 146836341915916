import HomePage from "../components/home/HomePage";
import Meta from "../components/Meta";
import en from '../contents/metas/en';
import tr from '../contents/metas/tr';
import { useRouter } from 'next/router';

const Home = (props) => {

  const router = useRouter();
  let t = router.locale === 'tr' ? tr : en;

  return (
    <>
      <Meta
        title={t.home.title}
        keywords={t.home.keywords}
        description={t.home.description}
        alternateLanguageEn={`https://${props.host}`}
        alternateLanguage={`https://${props.host}/tr`}/>
      <HomePage datas={t.home} modal={props.modal} />
    </>
  )
}

export async function getServerSideProps(context) {
  const {req} = context
  let host
  if(req.headers.host) host = req.headers.host;
  return {
    props: {
      host
    }, // will be passed to the page component as props
  }
}

export default Home