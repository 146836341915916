import NonSlider from './items/NonSlider';
import WhyChoose from './items/WhyChoose';
import PentestHome from './items/PentestHome';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useRouter } from 'next/router';

import YoutubeModal from './items/YoutubeModal';
import { useSelector, useDispatch } from 'react-redux';
import { set_modal } from '../../redux/actions/appActions'
import dynamic from 'next/dynamic'

const DataManagement = dynamic(
    () => import('./items/DataManagement')
)

// const TscmPage = dynamic(
//     () => import('./items/TscmPage')
// )

const Danismanlik = dynamic(
    () => import('./items/Danismanlik')
)

const ProcessStep = dynamic(
    () => import('./items/ProcessStep')
)

export default function HomePage({ datas }) {

    const size = useWindowSize();
    const isModal = useSelector(state => state.app.isModalPos)
    const dispatch = useDispatch();
    const router = useRouter();

    const toggleModal = (pos) => {
        dispatch(set_modal(pos))
    }


    return (
        <div onClick={() => { if (isModal) toggleModal(false) }}>
            {router.locale === "tr" && isModal &&
                <YoutubeModal
                    shown={isModal}
                    close={toggleModal} />
            }
            <NonSlider datasSlider={datas}
                close={toggleModal} />
            <WhyChoose chooseDatas={datas.whyDatas} />
            <PentestHome dataPentest={datas.pentestDatas} />
            <DataManagement dataIt={datas.itDatas} />
            {/* <TscmPage dataTscm={datas.tscmDatas} width={size.width}/> */}
            <Danismanlik dataConsult={datas.dataConsult} locale={router.locale} />
            <ProcessStep dataTools={datas.toolsData} />
        </div>
    )
}
