
export default function HizmetlerTitle({ title }) {
    return (
        <section className="section section-no-border section-angled order-0 my-0 pb-0">
            <div className="section-angled-layer-top bg-background-home"
                style={{ transform: "skewY(1.7deg) translate3d(0, -50%, 0)" , paddingBottom:"4em"}}>
            </div>
            <div className="container" style={{ paddingTop: "3em" }}>
                <h2 className="text-11 text-center font-weight-semibold text-color-secondary line-height-3 mb-lg-3 mb-2">
                    {title}
                </h2>
            </div>
        </section>
    )
}
