import { useRef, useEffect } from 'react'

export default function YoutubeModal({
    shown, close
}) {

    const videoYoutube = useRef(null);

    useEffect(() => {
        if (videoYoutube.current && !shown) {
            videoYoutube.current.src = '';
        }
        if (videoYoutube.current && shown) {
            videoYoutube.current.src = 'https://youtube.com/embed/SacNc1jz-i8';
        }
    }, [shown])




    return (
        <div className={shown ? "modal fade show" : "modal fade"}
            style={shown ? { display: "block", verticalAlign: "middle" } : { display: "none" }}
            tabIndex={-1}
            id="#myModal"
            role="dialog" aria-labelledby="def24" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <div className="modal-body">
                        <button type="button" className="close"
                            onClick={() => close(false)}
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>

                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe
                                ref={videoYoutube}
                                src='https://youtube.com/embed/SacNc1jz-i8'
                                frameBorder='0'
                                className="embed-responsive-item"
                                id="presentation"
                                width="100%"
                                height="100%"
                                allow='autoplay; encrypted-media'
                                allowFullScreen
                                title='video'

                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
