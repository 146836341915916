import { set_modal } from '../../../redux/actions/appActions'
import { useDispatch } from 'react-redux';
import Image from 'next/image';
// import CaretRight from '../../ui/svgs/CaretRight';
import PlayIcon from '../../ui/svgs/PlayIcon';


export default function NonSlider({ datasSlider, width, close }) {

    const dispatch = useDispatch()

    return (
        <div className="p-relative" style={{ position: "relative", overflow: "hidden" }}>
            <section
                className="section section-concept section-no-border section-tertiary 
                section-angled section-angled-reverse m-0"
                id="section-concept">
                <Image
                    src="/images/home.jpg"
                    alt="homepage"
                    layout="fill"
                    objectFit="cover"
                    quality="50"
                    loading="eager"
                    priority={true}
                />
                <div className="container pt-lg-3 mt-lg-3 mt-1">
                    <div className="row align-items-center pt-3">
                        <div className="col-12 mb-1" style={{ minHeight: "70vh" }}>
                            <div className="row"
                                style={  width > 991 ? { paddingTop: "11em" } : { paddingTop: "7em" }}>
                                <div className="col-md-5 d-flex flex-column">
                                    <h1 className="font-weight-bold text-15 text-primary
                                    text_align_center line-height-2 mb-1 mb-lg-3">
                                        {datasSlider.slogan}
                                    </h1>

                                    {datasSlider.videoButton &&
                                        <div className="p-relative text_align_center"
                                            style={{ margin: "7em 3em" }}>
                                            <button type="button"
                                                onClick={() => {
                                                    dispatch(set_modal(true))
                                                    close(true)
                                                }}
                                                aria-label="youtube modal"
                                                className="p-absolute transform3dxy-n50 rounded-circle 
                                    play-button-lg pulseAnim pulseAnimAnimated">
                                                <PlayIcon />
                                            </button>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        <div className="section-angled-layer-bottom bg-background-home"
                            style={{ padding: "5em 0" }}></div>
                        <div className="col-md-8 col-xl-5 custom-header-bar mt-3 py-3 pr-5 z-index-2">
                            <h2 className="position-relative text-center font-weight-bold 
                                    text-7 line-height-3 mb-0 ls-1">
                                {datasSlider.orangeTitle}
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
