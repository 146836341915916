import Link from 'next/link';
import CaretRight from '../../ui/svgs/CaretRight';

export default function ButtonLink({title, page}) {
    return (
        <Link href={page} passHref>
			<a className="btn text-4-5 text-light 
			bg-secondary p-2 mt-2 rounded-1"> {title}
			<CaretRight fill="caret-right-button" className="ml-1 mb-1"/>
			</a>
		</Link>
    )
}
