import Image from 'next/image';


export default function WhyChooseItem({ src, lowerTitle, definition, alt }) {
    return (
        <div className="col-10 col-sm-6 col-lg-4 mb-lg-5 text_align_center">
            <div className="overflow-hidden mb-lg-3 mb-2 mx-auto ml-md-5"
                style={{ height: "12em", width: "12em", position: "relative" }}>
                <Image
                    alt={alt}
                    src={src}
                    layout="fill"
                    objectFit="cover"
                    loading="eager"
                    data-src={src}
                />
            </div>
            <div className="whychoose-item my-2">
                <h4 className="font-weight-semibold line-height-4 text-color-primary mb-2 text-6">
                    {lowerTitle} </h4>
                <p className="text-5 line-height-2 text-color-primary">
                    {definition}
                </p>
            </div>
        </div>
    )
}
